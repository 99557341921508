import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import ComponentStyles from '../brand-styles/pages/terms-of-use.styles';

const StyledTermsOfUse = styled.div`
  margin: auto;
  ol {
    li {
      margin-bottom: 20px;

      ol {
        margin-top: 20px;
        li {
          list-style-type: lower-alpha;
        }
      }
    }
  }

  ul {
    li {
      margin-bottom: 5px;
    }
  }

   /* Dynamic brand styles */
   ${ComponentStyles}
`;

const TermsOfUse = ({ data }) => {
  const { content } = data.wordpressPage;
  return (
    <StyledTermsOfUse style={{ padding: '40px 20px' }} className="page-container">
      <Helmet>
        <title>{data.wordpressPage.yoast_head_json.title}</title>
        <meta
          name="description"
          content={data.wordpressPage.yoast_head_json.description}
        />
      </Helmet>
      {ReactHtmlParser(content)}
    </StyledTermsOfUse>
  );
};

export default TermsOfUse;

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "terms-of-use" }) {
      yoast_head_json {
        title
        description
      }
      content
    }
  }
`;
